<template>
  <v-card max-width="100vh">
    <v-card-title>
      <div
        class="text-h6 grey--text darken-4"
        style="overflow-wrap: break-word; display: inline-block"
      >
        {{ approval.title }}
      </div>
      <v-spacer> </v-spacer>
    </v-card-title>
    <v-card-subtitle>
      <span class="grey--text lighten-4 caption">
        Requested By {{ approval.requestedBy }} @
        {{ new Date(approval.dateAdded).toLocaleString("en-GB") }}
      </span>
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text>
      <div class="pa-4 overflow-auto" v-html="approval.content"></div>
    </v-card-text>
    <v-card-text>
      <v-textarea :disabled="hasBeenActioned" filled label="Agent Feedback" v-model="actionFeedBack">
      </v-textarea>
    </v-card-text>
    <v-card-actions class="px-8 pb-8">
      <!-- <v-spacer
        v-if="!$vuetify.breakpoint.xsOnly && !$vuetify.breakpoint.smOnly"
      ></v-spacer> -->
      <v-expand-transition>
        <v-row v-show="!hasBeenActioned" class="w-100">
          <v-col>
            <v-btn
              color="success"
              :block="$vuetify.breakpoint.smAndDown"
              class="mr-md-2 mr-lg-2 mb-2 mb-md-0 mb-lg-0 mb-xl-0"
              @click="approved"
            >
              <v-icon class="mr-2"> mdi-check </v-icon>
              Approve
            </v-btn>
            <v-btn
              color="red"
              class="white--text"
              :block="$vuetify.breakpoint.smAndDown"
              @click="denied"
            >
              <v-icon class="mr-2"> mdi-cancel </v-icon>
              Deny
            </v-btn>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    approval: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    approved() {
      this.$emit("approved", this.actionFeedBack);
      this.setHadBeenActioned();
    },
    denied() {
      this.$emit("denied", this.actionFeedBack);
      this.setHadBeenActioned();
    },
    setHadBeenActioned() {
      setTimeout(() => {
        this.hasBeenActioned = true;
      }, 400);
    },
  },
  data() {
    return {
      hasBeenActioned: false,
      actionFeedBack: ''
    };
  },
};
</script>

<style>
img {
  max-height: 80vh;
  max-width: 80vh;
}
</style>