import * as GeneralUtilities from "@/utils/GeneralUtilities";

export const GetApproval = async (uniqueString, emailAddress) => {
    try {
        var appResp = await fetch(GeneralUtilities.GetUrl() + "/" + uniqueString + '?emailAddress=' + emailAddress);
        if (appResp.ok) {
            var approval = await appResp.json();
            return approval;
        }
        else{
            throw new Error('Combination of Unique String and Email Address returned no Approval')
        }
    } catch (exception) {
        throw new Error(exception);
    }
}

export const ApproveApproval = async (uniqueString, emailAddress, feedBack) => {
    try{
        var resp = await fetch(GeneralUtilities.GetUrl() + '/' + uniqueString + '?actionResult=true&emailAddress=' + emailAddress + '&FeedBackMessage=' + feedBack, {
            method: 'POST'
        });

        if(resp.ok){
            return true;
        }
        else{
            throw new Error('Combination of Unique String and Email Address returned no Approval');
        }
    }
    catch(exception){
        throw new Error(exception);
    }
}

export const DenyApproval = async (uniqueString, emailAddress, feedBack) => {
    try{
        var resp = await fetch(GeneralUtilities.GetUrl() + '/' + uniqueString + '?actionResult=false&emailAddress=' + emailAddress + '&FeedBackMessage=' + feedBack, {
            method: 'POST'
        });

        if(resp.ok){
            return true;
        }else{
            throw new Error('Combination of Unique String and Email Address returned no Approval');
        }
    }
    catch(exception){
        throw new Error(exception);
    }
}