<template>
  <v-container>
    <v-row class="mt-12">
      <v-col cols="3"></v-col>
      <v-col cols="6" class="text-center">
        <div class="text-h1 grey--text">
          404, Oops
        </div>
        <h1 class="mt-6">
          {{ $route.params.message ?? "There was an error" }}
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  created() {
  },
  data() {
    return {
      dogURL: "",
    };
  },
};
</script>

<style>
</style>