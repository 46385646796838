import Vue from 'vue'
import VueRouter from 'vue-router'
import ApprovalView from '../views/ApprovalView'
import ErrorView from '../views/ErrorView'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/approval_view'
  },
  {
    name: 'ApprovalView',
    path: '/approval_view',
    component: ApprovalView
  },
  {
    name: 'Error',
    path: '/message',
    component: ErrorView
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
