<template>
  <v-app>
    <v-app-bar app>
      <div class="d-flex align-center">
        <!-- <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon> -->
        <a href="https://coffeecupsolutions.com" target="_blank">
          <v-img
            alt="Vuetify Name"
            class="shrink mt-1"
            contain
            min-width="100"
            src="./assets/cclogo.webp"
            width="150"
          />
        </a>
      </div>
      <v-spacer></v-spacer>
      <v-btn text href="tel:0118 38 42 175" class="hidden-sm-and-down">
        Call us at
        <span class="ml-2" style="color: #009bdb"> (+44) 0118 38 42 175 </span>
      </v-btn>
      <v-btn
        text
        href="mailto:hello@coffeecupsolutions.com"
        class="hidden-sm-and-down"
      >
        Email us at
        <span class="ml-2" style="color: #009bdb">
          hello@coffeecupsolutions.com
        </span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-btn
      elevation="2"
      rounded
      fixed
      right
      bottom
      color="primary"
      href="https://www.coffeecupsolutions.com/remote-support/"
      class="hidden-sm-and-down"
    >
      <v-icon class="mr-2"> mdi-face-agent </v-icon>
      Live Chat with us
    </v-btn>

    <!-- <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-title>
              Call us at              
              <v-btn text href="tel:0118 38 42 175">
                <span class="ml-2" style="color: #009bdb">
                  (+44) 0118 38 42 175
                </span>
              </v-btn></v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <v-btn text href="mailto:hello@coffeecupsolutions.com">
                Email us at
                <span class="ml-2" style="color: #009bdb">
                  hello@coffeecupsolutions.com
                </span>
              </v-btn></v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->
  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {},
  created() {},
  methods: {},

  data: () => ({
    drawer: false,
  }),
};
</script>


