<template>
  <v-container fluid>
    <v-row class="mt-md-12 mt-lg-12 mt-xl-12 mt-sm-6 mt-xs-4">
      <v-col lg="3" md="2" sm="0" xs="0" class="hidden-sm-and-down"></v-col>
      <v-col lg="6" md="8" sm="12" xs="12">
        <v-expand-transition>
          <v-alert class="white--text" color="info" v-show="hasResponded">
            <v-icon class="mr-2 white--text" large> mdi-cloud-check </v-icon>
            Thank you for actioning this approval, you can leave / close this
            page
          </v-alert>
        </v-expand-transition>
        <approval-card
          v-if="approvalHasLoaded"
          :approval="approval"
          @approved="approved"
          @denied="denied"
        >
        </approval-card>
        <v-card v-else>
          <v-skeleton-loader
            type="list-item-avatar,divider,article, actions"
          ></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as approvalAPI from "@/API/Approvals.js";

import ApprovalCard from "@/components/ApprovalCard.vue";
export default {
  components: { ApprovalCard },
  created() {
    //Redirect to error if no query params
    if (!this.isApprovalQueriesValid) {
      this.$router.push({
        name: "Error",
        params: { message: "Oops, there's nothing here!" },
      });
    } else {
      setTimeout(() => {
        this.getApproval();
      }, 600);
    }
  },
  computed: {
    uniqueString() {
      return this.$route.query.s ? this.$route.query.s : "";
    },
    emailAddress() {
      return this.$route.query.e ? this.$route.query.e : "";
    },
    isApprovalQueriesValid() {
      return (
        this.uniqueString &&
        this.emailAddress &&
        this.uniqueString != "" &&
        this.emailAddress != ""
      );
    },
  },
  methods: {
    async approved(feedback) {
      try {
        await approvalAPI.ApproveApproval(this.uniqueString, this.emailAddress, feedback);
        this.setHasReponded();
      } catch (exception) {
        this.$router.push({ name: "Error", params: { message: exception } });
      }
    },
    async denied(feedback) {
      try {
        await approvalAPI.DenyApproval(this.uniqueString, this.emailAddress, feedback);
        this.setHasReponded();
      } catch (exception) {
        this.$router.push({ name: "Error", params: { message: exception } });
      }
    },
    setHasReponded() {
      setTimeout(() => {
        this.hasResponded = true;
      }, 600);
    },
    async getApproval() {
      try {
        var approval = await approvalAPI.GetApproval(
          this.uniqueString,
          this.emailAddress
        );
        this.approval = approval;
        this.approvalHasLoaded = true;
      } catch (exception) {
        this.$router.push({
          name: "Error",
          params: {
            message:
              "This Approval cannot be found or has already been actioned.",
          },
        });
      }
    },
  },
  data() {
    return {
      approvedStatus: "",
      hasResponded: false,
      approvalHasLoaded: false,
      approval: {
        title: "",
        content: "",
        RequestedBy: "",
        DateAdded: "",
      },
    };
  },
};
</script>

<style>
</style>